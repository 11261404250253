import * as React from 'react';
import  MaskedInput from 'react-text-mask';

import {FieldProps} from 'formik';

export const defaultPhoneMask = ['+', '1', ' ', '(', /[1-9]/, /\d/, /\d/, ')', ' ', /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/];

export const allCountriesPhoneMask = ['+', /\d/, ' ', '(', /[1-9]/, /\d/, /\d/, ')', ' ', /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/];

export const phoneReplace = (phone: string | null | undefined): string => phone ? phone.replace(/[\(\)\-\s]/g, '') : '';

export const phoneFormat = (phone: string | null | undefined): string => phone ? phone.replace(/^\+1(\d{3})(\d{3})(\d{4})$/, '+1 ($1) $2-$3') : '';

interface Props {
	placeholder?: string;
	fieldProps: FieldProps;
	className?: string;
	mask?: (string | RegExp)[];
	withReplace?: boolean;
	isMobile?: boolean;
	disabled?: boolean;
}

export const FormikPhoneControl: React.FC<Props> = ({
	placeholder = '', 
	fieldProps: {field, form},
	className = 'form-control',
	mask = defaultPhoneMask,
	withReplace = true,
	isMobile = false,
	disabled
}) => {
	const value = React.useMemo(() => phoneFormat(field.value), [field.value]);
	
	return <MaskedInput
		type={isMobile ? 'tel' : 'text'}
		mask={mask}
		className={className}
		placeholder={placeholder}
		guide={true}
		id={field.name}
		onChange={e => form.setFieldValue(field.name, withReplace ? phoneReplace(e.target.value) : e.target.value)}
		onBlur={field.onBlur}
		value={value}
		disabled={disabled}
	/>;
};