import React from 'react';

interface Props {
	inner: (showed: boolean) => React.ReactNode;
	onScroll?: (e) => void;
	className?: string;
	threshold?: number;
}

const ElementWithIntersectionObserver: React.FC<Props> = ({inner, onScroll, className, threshold = 0.3}) => {
	const ref = React.useRef(null);
	const [active, setActive] = React.useState(false);

	React.useEffect(() => {
		const element = ref.current;

		if (element) {
			const observer = new IntersectionObserver(([e]) => {
				setActive(active => e.isIntersecting || active);
				if (onScroll) {
					if (e.isIntersecting) {
						window.addEventListener('scroll', onScroll);
					} else {
						window.removeEventListener('scroll', onScroll);
					}
				}
			}, { threshold: [threshold] });

			observer.observe(element);
			return () => observer.disconnect();
		}
	}, []);

	return <div className={className} ref={ref}>{inner(active)}</div>;
};

export default ElementWithIntersectionObserver;
