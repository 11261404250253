import React from 'react';

import { FieldProps, Form, Formik, FormikProps } from 'formik';
import * as Yup from 'yup';
import Message from 'antd/lib/message';
import 'antd/lib/message/style/index.css';
import 'antd/lib/select/style/index.css';
import MaskedInput from 'react-text-mask';

import FormikField from '@common/react/components/Forms/FormikField/FormikField';
import {
	simpleStringValidator,
	emailValidator,
	phoneValidator,
} from '@common/react/utils/validationHelpers';
import { request } from '@common/react/components/Api';
import {
	defaultPhoneMask,
} from '@common/react/components/Forms/FormikPhoneControl/FormikPhoneControl';
import { State } from '@common/react/components/Forms/State/State';
import { Loading } from '@common/react/components/UI/Loading/Loading';

import '@app/scss/components/newContactForm.scss';

interface FormValues {
	name: string;
	email: string;
	text: string;
	phone: string;
	state: string;
}

interface Props {
	className?: string;
	forContactUs?: boolean;
	title?: string;
	withGradient?: boolean;
	demo?: boolean;
}

const initialValues: FormValues = {
	name: '',
	email: '',
	text: '',
	phone: '',
	state: ''
};

const validationSchema = Yup.object().shape({
	name: simpleStringValidator,
	phone: phoneValidator,
	email: emailValidator,
	text: simpleStringValidator
});

const NewConcatForm: React.FC<Props> = ({className, title, withGradient, demo = false}) => {
	const [loading, setLoading] = React.useState(false);
	const [visible, setVisible] = React.useState(true);
	const [error, setError] = React.useState('');

	React.useEffect(() => {
		if (!loading) {
			const timer = setTimeout(() => setVisible(true), error ? 1000 : 2000);

			return () => clearTimeout(timer);
		}
	}, [loading, error]);

	const handleSubmit = (values, formikHelpers) => {
		setLoading(true);
		setVisible(false);
		setError('');

		request<FormValues, any, any>('inquiry', {
			name: values.name,
			email: values.email,
			text: values.text,
			phone: values.phone,
			state: values.state,
			demo: demo,
		})
			.then((res) => {
				setTimeout(() => {
					formikHelpers.resetForm({values: initialValues});
				}, 800);

				Message.success('Email sent');
			})
			.catch((e) => {
				setError('Something went wrong');
				Message.error('Something went wrong');
			})
			.finally(() => {
				setLoading(false);
			});
	};

	const handleChange = (
		e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
		form: FormikProps<FormValues>, name) => {
		const {value} = e.currentTarget;
		form.setFieldValue(name, value);
	};

	return (
		<div className="new-contact-form clearfix">
			{withGradient ? <section className="gradient-top"/> : null}
			<picture>
				<source media="(max-width: 500px)" srcSet="/images/background-p-500.jpeg"/>
				<source media="(min-width: 800px)" srcSet="/images/background-p-800.jpeg"/>
				<source media="(min-width: 1080px)" srcSet="/images/background-p-1080.jpeg"/>
				<img width="150" height="65" className="bg-image" alt="background" aria-hidden="true" src="/images/background-p-1600.jpeg"/>
			</picture>
			<div className="new-contact-form__content-wrapper">
				<div className="row clearfix">
					{title ? <h2 className="new-contact-form__title pull-left">
						{title}
					</h2> : null}
					<div className="inquiry-form-info pull-left">
						(561) 512-5313
						<br/>
						sales@executivetags.com
						<br/>
						1649 NORTH MILITARY TRAIL
						<br/>
						WEST PALM BEACH, FL 33409
					</div>
				</div>
				<div className={`col-sm-12 inquiry-form-container ${!visible ? 'form-sending' : ''}`}>
					{loading && <div className="is-relative loader">
						<Loading/>
					</div>}
					<div className="inquiry-form-wrapper">
						<Formik
							initialValues={initialValues}
							onSubmit={handleSubmit}
							validationSchema={validationSchema}
						>
							{(formikBag: FormikProps<FormValues>) => {
								return (<Form id="inquiry">
										<div className="row">
											<FormikField
												fieldName="name"
												containerClassName="custom-input-box col-sm-6"
												render={({field, form}: FieldProps<any, FormValues>) => (<>
														<input
															name="name"
															type="text"
															maxLength={100}
															value={field.value}
															className="form-control"
															placeholder="Name"
															onChange={(e: any) => handleChange(e, form, field.name)}
															required
														/>
													</>
												)}
											/>
											<FormikField
												fieldName="state"
												containerClassName="custom-input-box col-sm-6"
												render={({field, form}) => (
													<div className={field.value ? '' : 'empty-select'}>
														<State
															onChange={state => form.setFieldValue(field.name, state)}
															value={field.value}
															defaultCaption="State"
														/>
													</div>
												)}
											/>
											<FormikField
												fieldName="email"
												containerClassName="custom-input-box col-sm-6"
												render={({field, form}: FieldProps<any, FormValues>) => (<>
														<input
															name="email"
															type="email"
															maxLength={100}
															value={field.value}
															className="form-control"
															placeholder="Email"
															onChange={(e: any) => handleChange(e, form, field.name)}
															required
														/>
													</>
												)}
											/>
											<FormikField
												fieldName="phone"
												containerClassName="custom-input-box col-sm-6"
												inputProps={{placeholder: 'Phone'}}
												render={({field, form}: FieldProps<any, FormValues>) => (<>
														<MaskedInput
															name="phone"
															maxLength={100}
															type="phone"
															value={field.value}
															className="form-control"
															placeholder="Phone"
															onChange={(e: any) => handleChange(e, form, field.name)}
															required
															mask={defaultPhoneMask}
															guide={true}
														/>
													</>
												)}
											/>
											<FormikField
												fieldName="text"
												containerClassName="custom-input-box col-sm-12"
												render={({field, form}: FieldProps<any, FormValues>) => (<>
														<textarea
															name="text"
															rows={10}
															cols={20}
															value={field.value}
															className="form-control"
															placeholder="Message"
															style={{height: 100, maxHeight: 300, minHeight: 100}}
															onChange={(e: any) => handleChange(e, form, field.name)}
															required
														/>
													</>
												)}
											/>
										</div>
										<button
											type="submit"
											className="btn submit-button"
										>
											Send
										</button>
									</Form>
								);
							}}
						</Formik>
					</div>
				</div>
			</div>
		</div>
	);
};

export default NewConcatForm;
